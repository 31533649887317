(function () {
  'use strict';

  class TeacherReportCtrl {
    constructor(Utils, Polls, Discussions, $scope, $window, $rootScope, $filter) {
      let vm = this;
      vm.Utils = Utils;
      vm.Polls = Polls;
      vm.$scope = $scope;
      vm.$filter = $filter;
      vm.Discussions = Discussions;
      vm.user = $rootScope.user;
      vm.counter = 0;
      vm.barChartOptions = {
        chart: {
          type: 'discreteBarChart',
          height: 450,
          x: function (d) {
            return d.label;
          },
          y: function (d) {
            return d.value;
          },
          showValues: true,
          valueFormat: function (d) {
            return $window.d3.format(',d')(d);
          },
          duration: 500,
          xAxis: {
            axisLabel: 'Answer'
          },
          yAxis: {
            axisLabel: 'Count',
            axisLabelDistance: -10
          }
        }
      };
      vm.init();
    }
    refresh() {
      let vm = this;
      delete vm.allpolls;
      delete vm.polls;
      delete vm.discussions;
      vm.init();
    }
    init() {
      let vm = this,
          classroom_id = vm.Utils.getClassroom() || vm.user.classroom_ids[0];
      if (classroom_id) {
        vm.Polls.pollsGet({classroom_id: classroom_id})
          .then((data) => {
            vm.allpolls = data.documents;
            vm.polls = [vm.allpolls[vm.counter]];
          });
        vm.Discussions.latest({classroom_id: classroom_id, time: Date.now()})
          .then((data) => {
            vm.discussions = data.documents;
          });
      } else {
        vm.error = 'No classroom found in context.';
      }
    }
    setGraph(poll, data) {
      let graph = [
          {
            key: 'Results',
            values: []
          }
      ];
      angular.forEach(data, function (v) {
        graph[0].values.push({label: v.option_label, value: v.count});
      });
      poll.graph = graph;
    }
    prevPoll() {
      let vm = this;
      vm.counter--;
      vm.polls = [vm.allpolls[vm.counter]];
    }
    nextPoll() {
      let vm = this;
      vm.counter++;
      vm.polls = [vm.allpolls[vm.counter]];
    }
  }

  /**
   * @ngdoc object
   * @name components.controller:TeacherReportCtrl
   *
   * @description
   *
   */
  angular
    .module('components')
    .controller('TeacherReportCtrl', TeacherReportCtrl);
}());
